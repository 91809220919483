<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="searchform" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.kname" @change="Search" placeholder="模糊搜索名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.mailbox_type" @change="Search" placeholder="邮箱类型编号"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="AddsenderManage">新建</el-button>
      </div>
    </div>

    <el-table border v-bind:data="list">
      <el-table-column label="创建人名称">
        <template v-slot="scope">
          <span>{{ scope.row.adminer_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建人账号">
        <template v-slot="scope">
          <span>{{ scope.row.adminer_account }}</span>
        </template>
      </el-table-column>
      <el-table-column label="邮箱类型">
        <template v-slot="scope">
          <span>{{ scope.row.mailbox_type }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发件人显示名称">
        <template v-slot="scope">
          <span>{{ scope.row.from_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="发件人账号">
        <template v-slot="scope">
          <span>{{ scope.row.username }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="primary" size="mini" @click="Edit(scope.row)">修改</el-button>
          <el-button type="danger" size="mini" @click="Delete(scope.row)">删除</el-button>
          <router-link :to="'/message/sender/user/'+scope.row.code" style="margin-left:10px">
            <el-button type="info" size="mini">使用者</el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog :title="edit+'发件人'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="发件人账号" prop="username" label-width="130px">
          <el-input v-model="form.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发件人密码" prop="password" label-width="130px">
          <el-input v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发件人显示名称" prop="from_name" label-width="130px">
          <el-input v-model="form.from_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱类型编号" prop="mailbox_type" label-width="130px">
          <el-select v-model="form.mailbox_type">
            <el-option
              v-for="item in mailTypeList"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="说明" prop="explain" label-width="130px">
          <el-input type="textarea" v-model="form.explain" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="Validate(Addconfirm)">编辑</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: '',
        mailbox_type: '',
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      form: {
        code: '',
        username: '',
        password: '',
        from_name: '',
        mailbox_type: '',
        explain: '',
      },
      rules: {
        username: [{ required: true, message: '请输入发件人账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入发件人密码', trigger: 'blur' }],
        from_name: [{ required: true, message: '请输入发件人显示名称', trigger: 'blur' }],
        mailbox_type: [{ required: true, message: '请输入邮箱类型编号', trigger: 'blur' }],
        explain: [{ required: true, message: '请输入说明', trigger: 'blur' }],
      },
      mailTypeList: [],
      edit: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetMailTYpe () {
      this.api.EmailTypeList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.mailTypeList = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search () {
      this.api.EmailSenderList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    AddsenderManage(){
      this.edit = '新增'
      this.form = {}
      this.dialogFormVisible = true
    },
    Update(){
      this.api.EmailSenderUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.edit +'成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Addconfirm(){
      this.Update()
    },
    Edit(item) {
      this.edit = '修改'
      this.form = {}
      this.dialogFormVisible = true
      this.api.EmailSenderDetail({code: item.code}).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Delete(item) {
      this.$confirm('是否删除该发件人?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.EmailSenderDelete({
          code: item.code,
        }).then(res => {
           if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () {
    this.GetMailTYpe()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
